import { ETheme } from "../config/enum"
import React from "react"
import { createContext, ReactNode, useContext } from "react"

type ThemeContextType = {
  theme: ETheme
  toggleTheme: () => void
}

type ThemeProviderProps = {
  children: ReactNode
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = React.useState<ETheme>(()=> {
    if (typeof window !== "undefined") {
      return localStorage.getItem('theme') as ETheme;
    }
    return ETheme.Dark;
  })

  React.useEffect(() => {
    const storedTheme = localStorage.getItem("theme") as ETheme
    const systemTheme = window.matchMedia("(prefers-color-scheme:dark)").matches
      ? ETheme.Dark
      : ETheme.Light
    if (storedTheme) {
      setTheme(storedTheme)
    } else {
      setTheme(systemTheme)
    }
  }, [])

  React.useEffect(() => {
    document.body.classList.remove(ETheme.Light, ETheme.Dark)
    document.body.classList.add(theme)
    localStorage.setItem("theme", theme)
  }, [theme])

  const toggleTheme = () => {
    setTheme(theme === ETheme.Light ? ETheme.Dark : ETheme.Light)
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
